export interface ApplicantSummary {
    address: string;
    birthDate: string;
    city: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    relationship: string;
    state: string;
    zipCode: string;
}

export interface BillingAddress {
    address: string;
    city: string;
    state: string;
    zipCode: string;
}

export interface ResponsiblePartySummary {
    address: string;
    birthDate: string;
    city: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    relationship: string;
    state: string;
    zipCode: string;
}

export interface AutoRenewalDetail {
    autoRenewalPaymentText: string;
    autoRenewalText: string;
    autoRenewalRequired: boolean;
    autoRenewalOffered: boolean;
    autoRenewalPromoCodeText: string;
}


export interface PortalPurchaseSummaryLineItem {
    label: string;
    text: string;
}

export interface PortalPurchaseSummary {
    adminFee: number | null;
    adminFeeDiscount: number | null;
    associatedApplicants: ApplicantSummary[];
    billingAddress: BillingAddress;
    cardType: string;
    completed: string;
    coverageLevelName: string;
    minNumberMembers: number;
    maxNumberMembers: number;
    famPlanDiffPrice: number | null;
    effectiveDate: string | null;
    fee: number | null;
    feeDiscount: number | null;
    paymentAccount: string;
    paymentType: string;
    planName: string;
    planDuration: number;
    primaryApplicant: ApplicantSummary;
    responsibleParty: ResponsiblePartySummary;
    autoRenewalDetail: AutoRenewalDetail;
    promoCode: string;
    promoCodeDiscount: number;
    promoCodeLineItems: PortalPurchaseSummaryLineItem[];
    promoCodeName: string;
    purchaseId: string;
    total: number;
    started: string;
    salesTaxPercent: number;
    adminFeeSalesTaxCharge: number;
    feeSalesTaxCharge: number;
}

export interface TaxRequest {
  zipCode: string
}

export const defaultApplicantSummary: ApplicantSummary = {
    address: '',
    birthDate: '',
    city: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    relationship: '',
    state: '',
    zipCode: ''
};

// Initializer for BillingAddress
export const defaultBillingAddress: BillingAddress = {
    address: '',
    city: '',
    state: '',
    zipCode: ''
};

// Initializer for ResponsiblePartySummary
export const defaultResponsiblePartySummary: ResponsiblePartySummary = {
    address: '',
    birthDate: '',
    city: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    relationship: '',
    state: '',
    zipCode: ''
};

// Initializer for AutoRenewalDetail
export const defaultAutoRenewalDetail: AutoRenewalDetail = {
    autoRenewalPaymentText: '',
    autoRenewalText: '',
    autoRenewalRequired: false,
    autoRenewalOffered: false,
    autoRenewalPromoCodeText: ''
};

// Initializer for PortalPurchaseSummaryLineItem
export const defaultPortalPurchaseSummaryLineItem: PortalPurchaseSummaryLineItem = {
    label: '',
    text: ''
};

// Initializer for PortalPurchaseSummary
export const defaultPortalPurchaseSummary: PortalPurchaseSummary = {
    adminFee: null,
    adminFeeDiscount: null,
    associatedApplicants: [defaultApplicantSummary], // Initialize with defaultApplicantSummary
    billingAddress: defaultBillingAddress,
    cardType: '',
    completed: '',
    coverageLevelName: '',
    minNumberMembers: 0,
    maxNumberMembers: 0,
    famPlanDiffPrice: null,
    effectiveDate: null,
    fee: null,
    feeDiscount: null,
    paymentAccount: '',
    paymentType: '',
    planName: '',
    planDuration: 0,
    primaryApplicant: defaultApplicantSummary, // Initialize with defaultApplicantSummary
    responsibleParty: defaultResponsiblePartySummary,
    autoRenewalDetail: defaultAutoRenewalDetail,
    promoCode: '',
    promoCodeDiscount: 0,
    promoCodeLineItems: [defaultPortalPurchaseSummaryLineItem], // Initialize with defaultPortalPurchaseSummaryLineItem
    promoCodeName: '',
    purchaseId: '',
    total: 0,
    started: '',
    salesTaxPercent: 0,
    adminFeeSalesTaxCharge: 0,
    feeSalesTaxCharge: 0
};
