import { Component, OnInit } from '@angular/core';
import {EventsManager} from "../../events/events.manager";

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {
level:any = {};
step:number = 1;
showPrimary:boolean = false;
showPaymentScreen:boolean = false;
showRenewalDetails:boolean = false;
showSuccess:boolean = false;
showTaxLine: boolean = false;
taxAmount:number = 0;
today: Date = new Date();
  constructor(private em: EventsManager) { }

  ngOnInit(): void {
    this.level = this.em.getSelectedLevel();
    window.scrollTo(0,0);

    this.em.showPaymentScreen.subscribe(
      (result) => {
        this.showPaymentScreen = result;
        this.showRenewalDetails = result;
      }
    )

    this.em.showSuccessScreen.subscribe(
      (result) => {
        window.scrollTo(0,0);
        this.showPaymentScreen = !result;
        this.showRenewalDetails = !result;
        this.showSuccess = result;
      }
    )

    this.em.togglePrimary.subscribe(
      (result:any) => {
        window.scrollTo(0,0);
        this.showPrimary = result;
        this.step = 2;
      }
    )

    this.em.taxPercUpdated.subscribe(
      (result:number) => {
        this.taxAmount = result;
        if(this.taxAmount > 0){
          this.showTaxLine = true;
        }

      }
    )
  }

}
